import React, { useEffect } from 'react';
import { Flex, Text, Paper, Grid, Box, Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import useSources from '../../../AddObject/SanatoryForm/useSources';
import { SanatoryFormProps } from '../../types';
import { SANATORY_FIELDS } from '../../../AddObject/SanatoryForm/schema';

import ConditionalComponent from 'components/ConditionalComponent';
import TimezonesSelect from 'components/forms/TimezonesSelect';
import { Content } from 'components/ui/Layout';
import RegionSelectForm from 'components/ui/RegionSelectForm/RegionSelectForm';
import InputForm from 'components/ui/InputForm';
import DatePickerForm from 'components/ui/DatePickerForm';
import TextareaForm from 'components/ui/TextareaForm';
import SwitchForm from 'components/ui/SwitchForm';

type FormProps = {
  hotel: SanatoryFormProps;
};

function SanatoryFormEdit({ hotel }: FormProps) {
  const { t } = useTranslation();
  const {
    place_name,
    sources: hotelSources,
    address,
    legal_entity,
    name,
    currency,
    coordinates,
    timezone,
    email,
    phone,
    discount,
    cashback,
    instant_booking,
    external_provider,
    videos
  } = hotel;
  const { setValue } = useFormContext();

  const [sources, { fetch }]: any = useSources();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!hotel) {
      return;
    }
    setValue(SANATORY_FIELDS.LOCATION, address?.location);
    setValue(SANATORY_FIELDS.EMAIL, email);
    setValue(SANATORY_FIELDS.PHONE, phone);
    setValue(SANATORY_FIELDS.PHONE, address?.location);
    setValue(`${SANATORY_FIELDS.LEGAL_ENTITY}.${SANATORY_FIELDS.INN}`, legal_entity?.inn);
    setValue(`${SANATORY_FIELDS.LEGAL_ENTITY}.${SANATORY_FIELDS.KPP}`, legal_entity?.kpp);
    setValue(SANATORY_FIELDS.NAME, name);
    setValue(SANATORY_FIELDS.CURRENCY, currency);
    setValue(SANATORY_FIELDS.PLACE_ID, place_name);
    setValue(`${SANATORY_FIELDS.COORDINATES}.${SANATORY_FIELDS.LNG}`, coordinates?.lng);
    setValue(`${SANATORY_FIELDS.COORDINATES}.${SANATORY_FIELDS.LAT}`, coordinates?.lat);
    setValue(SANATORY_FIELDS.TIMEZONE, timezone);

    hotelSources?.forEach((source, index) => {
      setValue(`${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.INSURANCE_ID}.${index}`, source.insurance_id);

      if (source.contracts?.[0]) {
        setValue(
          `${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.NUMBER}`,
          source.contracts[0].number
        );

        setValue(
          `${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.COMMISSION}`,
          source.contracts[0].commission_in_percent
        );

        if (source.contracts[0].from_date) {
          const date = new Date(source.contracts[0].from_date);
          setValue(
            `${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.DATE}`,
            date
          );
        }
      }
    });

    setValue(SANATORY_FIELDS.DISCOUNT, discount);
    setValue(SANATORY_FIELDS.INSTANT_BOOKING, instant_booking);
    setValue(SANATORY_FIELDS.CASHBACK, cashback);

    setValue(
      `${SANATORY_FIELDS.EXTERNAL_PROVIDER}.${SANATORY_FIELDS.TRIPADVISOR_URL}`,
      external_provider?.tripadvisor_url || ''
    );
    setValue(SANATORY_FIELDS.VIDEOS, videos.map((video) => video.url).join(', '));
  }, [hotel, setValue]);

  return (
    <Content>
      <Flex gap='lg'>
        <Flex maw={650}>
          <Paper withBorder shadow='md' p={30} radius='md'>
            <Text fz={30}>{t('Objects.New.Form1.Title')}</Text>

            <Flex direction='column' gap='md'>
              <Text mt='md' fz={20}>
                {t('Objects.New.Form.All')}
              </Text>

              <InputForm
                name={SANATORY_FIELDS.LOCATION}
                label={t('Objects.New.Form.Address')}
                placeholder={t('Objects.New.Form.Address.Placeholder')}
                required
              />
            </Flex>

            <Flex direction='column' gap='md'>
              <Text mt='md' fz={20}>
                {t('Objects.New.Form.Law')}
              </Text>

              <Flex gap='md'>
                <InputForm
                  width='100%'
                  name={`${SANATORY_FIELDS.LEGAL_ENTITY}.${SANATORY_FIELDS.INN}`}
                  label={t('Objects.New.Form.Inn')}
                  placeholder={t('Objects.New.Form.Inn.Placeholder')}
                  required
                  type='number'
                />
                <InputForm
                  width='100%'
                  name={`${SANATORY_FIELDS.LEGAL_ENTITY}.${SANATORY_FIELDS.KPP}`}
                  label={t('Objects.New.Form.Kpp')}
                  placeholder={t('Objects.New.Form.Kpp.Placeholder')}
                  type='number'
                />
              </Flex>
            </Flex>

            <Flex direction='column' gap='md'>
              <Text mt='md' fz={20}>
                {t('Objects.New.Form.Search')}
              </Text>
              <RegionSelectForm
                label={t('Objects.New.Form.SearchArea')}
                placeholder={t('SearchArea.Objects.Placeholder')}
                name={SANATORY_FIELDS.PLACE_ID}
                defaultName={place_name}
                required
              />

              <Flex gap='md'>
                <InputForm
                  name={`${SANATORY_FIELDS.COORDINATES}.${SANATORY_FIELDS.LNG}`}
                  label={t('Objects.New.Form.Lng')}
                  placeholder={t('Objects.New.Form.Lng.Placeholder')}
                  required
                />
                <InputForm
                  name={`${SANATORY_FIELDS.COORDINATES}.${SANATORY_FIELDS.LAT}`}
                  label={t('Objects.New.Form.Lat')}
                  placeholder={t('Objects.New.Form.Lat.Placeholder')}
                  required
                />

                <TimezonesSelect name={SANATORY_FIELDS.TIMEZONE} label={t('Objects.New.Form.Timezone')} />
              </Flex>
            </Flex>

            <Text fz={20} mt='xl'>
              {t('Objects.New.Form.Special')}
            </Text>
            <Grid mt='sm'>
              <Grid.Col span={4}>
                <InputForm
                  name={SANATORY_FIELDS.DISCOUNT}
                  label={t('Objects.New.Form2.DiscountSize')}
                  placeholder={t('Objects.New.Form2.DiscountSize.Placeholder')}
                  type='number'
                />
              </Grid.Col>
              <Grid.Col span={4} pt='xs' mt='lg'>
                <SwitchForm name={SANATORY_FIELDS.INSTANT_BOOKING} label={t('Objects.New.Form2.InstantBooking')} />
              </Grid.Col>
              <Grid.Col span={4} pt='xs' mt='lg'>
                <SwitchForm name={SANATORY_FIELDS.CASHBACK} label={t('Objects.New.Form2.Cashback')} />
              </Grid.Col>
            </Grid>

            <Flex mt='lg' direction='column' gap='md'>
              <Checkbox disabled checked label='Включено отображение на сайте' />
              <Checkbox disabled label='Включено отображение в страховой' />
            </Flex>
          </Paper>
        </Flex>
        <Flex direction='column' gap='lg' maw={650}>
          <Paper withBorder shadow='md' p={30} radius='md'>
            <Text fz='30px'>{t('Objects.New.Form2.Title')}</Text>
            {/* // @ts-ignore */}
            {hotelSources?.map(({ insurance_id, contracts }: any, index: number) => {
              const name = sources?.find((item: any) => item?.id === insurance_id)?.name;
              return (
                <Flex key={insurance_id} direction='column' gap='md'>
                  <Text mt='md' fz={20}>
                    {name}
                  </Text>
                  <Flex gap='md'>
                    {/* // @ts-ignore */}
                    {contracts.map((_: any, contractIndex: number): any => (
                      <Flex gap='md' key={contractIndex}>
                        <InputForm
                          width='100%'
                          name={`${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.NUMBER}`}
                          label={t('Objects.New.Form2.Number')}
                          placeholder={t('Objects.New.Form2.Number.Placeholder')}
                          required
                        />

                        <DatePickerForm
                          required
                          format='DD.MM.YYYY'
                          width='100%'
                          placeholder='дд.мм.гггг'
                          label={t('Objects.New.Form2.Date')}
                          name={`${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.DATE}`}
                        />

                        <InputForm
                          width='100%'
                          name={`${SANATORY_FIELDS.SOURCES}.${index}.${SANATORY_FIELDS.CONTRACTS}.${index}.${SANATORY_FIELDS.COMMISSION}`}
                          label={t('Objects.New.Form2.Commission')}
                          placeholder={t('Objects.New.Form2.Commission.Placeholder')}
                          required
                          type='number'
                        />
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              );
            })}
          </Paper>
          <Paper withBorder shadow='md' p={30} radius='md'>
            <Text fz='30px'>{t('Objects.New.Form3.Title')}</Text>

            <Flex direction='column' gap='md' mb='xl'>
              <InputForm
                name={`${SANATORY_FIELDS.EXTERNAL_PROVIDER}.${SANATORY_FIELDS.TRIPADVISOR_URL}`}
                label={t('Objects.New.Form3.TripAdvisor')}
                placeholder={t('Objects.New.Form3.TripAdvisor.Placeholder')}
              />

              <TextareaForm
                name={SANATORY_FIELDS.VIDEOS}
                label={t('Objects.New.Form3.YoutubeLinks')}
                placeholder={t('Objects.New.Form3.YoutubeLinks.Placeholder')}
                minRows={2}
              />
            </Flex>
          </Paper>
        </Flex>
      </Flex>
    </Content>
  );
}

export default SanatoryFormEdit;
