import React, { useEffect } from 'react';
import { Text, Paper, Flex, Grid, Box, Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Content } from 'components/ui/Layout';
import RegionSelectForm from 'components/ui/RegionSelectForm/RegionSelectForm';
import TimezonesSelect from 'components/forms/TimezonesSelect';
import { SanatoryFormProps } from '../../types';
import { OSTROVOK_FIELDS } from '../../../AddObject/OstrovokForm/schema';
import InputForm from 'components/ui/InputForm';
import TextareaForm from 'components/ui/TextareaForm';
import SwitchForm from 'components/ui/SwitchForm';
import DatePickerForm from 'components/ui/DatePickerForm';
import ConditionalComponent from 'components/ConditionalComponent';
import useSources from '../../../AddObject/SanatoryForm/useSources';

type FormProps = {
  hotel: SanatoryFormProps;
};

function OstrovokFormEdit({ hotel }: FormProps) {
  const { t } = useTranslation();
  const {
    place_name,
    address,
    coordinates,
    timezone,
    provider_id,
    discount,
    cashback,
    instant_booking,
    external_provider,
    videos,
    sources: hotelSources
  } = hotel;

  const [sources, { fetch }]: any = useSources();

  useEffect(() => {
    fetch();
  }, []);

  const { setValue } = useFormContext();

  useEffect(() => {
    if (!hotel) {
      return;
    }
    setValue(OSTROVOK_FIELDS.LOCATION, address?.location);
    setValue(OSTROVOK_FIELDS.PLACE_ID, place_name);
    setValue(`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LNG}`, coordinates.lng);
    setValue(`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LAT}`, coordinates.lat);
    setValue(OSTROVOK_FIELDS.TIMEZONE, timezone);
    setValue(OSTROVOK_FIELDS.PROVIDER_ID, provider_id);

    setValue(OSTROVOK_FIELDS.DISCOUNT, discount);
    setValue(OSTROVOK_FIELDS.INSTANT_BOOKING, instant_booking);
    setValue(OSTROVOK_FIELDS.CASHBACK, cashback);

    setValue(
      `${OSTROVOK_FIELDS.EXTERNAL_PROVIDER}.${OSTROVOK_FIELDS.TRIPADVISOR_URL}`,
      external_provider?.tripadvisor_url || ''
    );

    hotelSources?.forEach((source, index) => {
      setValue(`${OSTROVOK_FIELDS.SOURCES}.${index}.${OSTROVOK_FIELDS.INSURANCE_ID}.${index}`, source.insurance_id);

      if (source.contracts?.[0]) {
        setValue(
            `${OSTROVOK_FIELDS.SOURCES}.${index}.${OSTROVOK_FIELDS.CONTRACTS}.${index}.${OSTROVOK_FIELDS.NUMBER}`,
            source.contracts[0].number
        );

        setValue(
            `${OSTROVOK_FIELDS.SOURCES}.${index}.${OSTROVOK_FIELDS.CONTRACTS}.${index}.${OSTROVOK_FIELDS.COMMISSION}`,
            source.contracts[0].commission_in_percent
        );

        if (source.contracts[0].from_date) {
          const date = new Date(source.contracts[0].from_date);
          setValue(
              `${OSTROVOK_FIELDS.SOURCES}.${index}.${OSTROVOK_FIELDS.CONTRACTS}.${index}.${OSTROVOK_FIELDS.DATE}`,
              date
          );
        }
      }
    });
    setValue(OSTROVOK_FIELDS.VIDEOS, videos.map((video) => video.url).join(', '));
  }, [hotel, setValue]);

  return (
    <Content>
      <Flex gap='lg'>
        <Paper w={650} withBorder shadow='md' p={30} radius='md'>
          <Text fz='30px'>{t('Objects.New.Form1.Title')}</Text>

          <Flex direction='column' gap='md' mb='xl'>
            <InputForm
              name={OSTROVOK_FIELDS.LOCATION}
              label={t('Objects.New.Form.Address')}
              placeholder={t('Objects.New.Form.Address.Placeholder')}
              required
            />

            <RegionSelectForm
              required
              defaultName={place_name}
              label={t('Objects.New.Form.SearchArea')}
              placeholder={t('SearchArea.Objects.Placeholder')}
              name={OSTROVOK_FIELDS.PLACE_ID}
            />

            <Flex gap='md'>
              <InputForm
                name={`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LNG}`}
                label={t('Objects.New.Form.Lng')}
                placeholder={t('Objects.New.Form.Lng.Placeholder')}
                required
              />

              <InputForm
                name={`${OSTROVOK_FIELDS.COORDINATES}.${OSTROVOK_FIELDS.LAT}`}
                label={t('Objects.New.Form.Lat')}
                placeholder={t('Objects.New.Form.Lat.Placeholder')}
                required
              />
              <TimezonesSelect required name={OSTROVOK_FIELDS.TIMEZONE} label={t('Objects.New.Form.Timezone')} />
            </Flex>
          </Flex>

          <Text fz={20}>{t('Objects.New.Form.Special')}</Text>
          <Grid mt='sm'>
            <Grid.Col span={4}>
              <InputForm
                name={OSTROVOK_FIELDS.DISCOUNT}
                label={t('Objects.New.Form2.DiscountSize')}
                placeholder={t('Objects.New.Form2.DiscountSize.Placeholder')}
                type='number'
                disabled={process.env.REACT_APP_HOTEL_DISCOUNT_ENABLED === '0'}
              />
            </Grid.Col>
            <Grid.Col span={4} pt='xs' mt='lg'>
              <SwitchForm name={OSTROVOK_FIELDS.INSTANT_BOOKING} label={t('Objects.New.Form2.InstantBooking')} />
            </Grid.Col>
            <Grid.Col span={4} pt='xs' mt='lg'>
              <SwitchForm name={OSTROVOK_FIELDS.CASHBACK} label={t('Objects.New.Form2.Cashback')} />
            </Grid.Col>
          </Grid>
          <Flex mt='lg' direction='column' gap='md'>
            <Checkbox disabled checked label='Включено отображение на сайте' />
            <Checkbox disabled label='Включено отображение в страховой' />
          </Flex>
        </Paper>
        <Flex direction='column' gap='lg' maw={650}>
          <Paper withBorder shadow='md' p={30} radius='md'>
            <Text fz='30px'>{t('Objects.New.Ostrovok.Title')}</Text>
            <Flex gap='lg'>
              {hotelSources?.map(({ insurance_id, contracts }: any, index: number) => {
                const name = sources?.find((item: any) => item?.id === insurance_id)?.name;
                return (
                  <Flex key={insurance_id} direction='column'>
                    <Flex>
                      {/* // @ts-ignore */}
                      {contracts.map((_: any, contractIndex: number): any => (
                        <Flex direction='column' gap='md' key={contractIndex}>
                          <Text mt='md' fz={20}>
                            {name}
                          </Text>
                          <InputForm
                            disabled
                            width='70%'
                            name={`${OSTROVOK_FIELDS.SOURCES}.${index}.${OSTROVOK_FIELDS.CONTRACTS}.${index}.${OSTROVOK_FIELDS.COMMISSION}`}
                            label={`${t('Objects.New.Form2.Ostrovok.Commission')}, %`}
                            placeholder={t('Objects.New.Form2.Ostrovok.Commission.Placeholder')}
                            required
                            type='number'
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Paper>
          <Paper withBorder shadow='md' p={30} radius='md'>
            <Text fz='30px'>{t('Objects.New.Form3.Title')}</Text>

            <Flex direction='column' gap='md' mb='xl'>
              <InputForm
                name={`${OSTROVOK_FIELDS.EXTERNAL_PROVIDER}.${OSTROVOK_FIELDS.TRIPADVISOR_URL}`}
                label={t('Objects.New.Form3.TripAdvisor')}
                placeholder={t('Objects.New.Form3.TripAdvisor.Placeholder')}
              />

              <TextareaForm
                name={OSTROVOK_FIELDS.VIDEOS}
                label={t('Objects.New.Form3.YoutubeLinks')}
                placeholder={t('Objects.New.Form3.YoutubeLinks.Placeholder')}
                minRows={2}
              />
            </Flex>
          </Paper>
        </Flex>
      </Flex>
    </Content>
  );
}

export default OstrovokFormEdit;
